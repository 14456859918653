import React, { useEffect, useState } from 'react';

import Init from '../../../../Init/Init';
import './PackagingCatalog.scss';

import LoadingPage from '../../../Utilities/LoadingPage/LoadingPage';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';

import useAnalyticsEventTracker from '../../../../GoogleAnalytics/useAnalyticsEventTracker';
import productAPI from '../../../../Data/Product';
import { Link } from 'react-router-dom';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Packaging Catalog';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const PackagingCatalogList = ({ catalog }) => {
    return (
        <div className="packaging-catalog-list">
            {
                catalog && catalog.map( (item, index) => {
                    return <PackagingCatalogItem 
                        key={index} 
                        productCode={ item.product_code }
                        productName={ item.product_name }
                        productImage={ item.product_image }                    
                    />;
                } )
            }
        </div>
    );
};

const PackagingCatalogItem = ({ productCode, productImage, productName }) => {
    return (
        <Link to={ `/product/${ productCode }` } className="packaging-catalog-item">
            <div className="packaging-catalog-item-image-container">
                <img src={ productImage } className='packaging-catalog-item-image' />
                <div className="detail-box">
                    Detail
                </div>
            </div>
            <div className="packaging-catalog-item-name">
                { productName }
            </div>
        </Link>
    );
}

const PackagingCatalog = (props) => {
    const cupIcon = Init.config.icons.cup;

    const [catalog, setCatalog] = useState([]);

    // Call API
    useEffect(() => {
        ( async() => {
            const response = await productAPI.productList();

            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                setCatalog(response.data);
            }
        } )();
        console.log("Render packaging catalog!");
    }, []);


    return (
        <div id="packaging-catalog" className="packaging-catalog">
            {/* 
                1. Header (Title, Desc, Icon)
                2. Catalog
            */}
            <div className="packaging-catalog-header">
                <div className="packaging-catalog-header-content">
                    <h2 className="packaging-catalog-title">
                        Produk Kami
                    </h2>
                    <p className="packaging-catalog-desc">
                        Kami memiliki banyak variasi kemasan produk yang dapat kalian kustomisasi dengan kebutuhan bisnis #PastiJadiKeren.
                    </p>
                </div>
                <div className="packaging-catalog-header-icon">
                    <img src={ cupIcon } alt="" />
                </div>
            </div>
            <PackagingCatalogList catalog={ catalog } />
        </div>
    );
}



export default PackagingCatalog;